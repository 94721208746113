import React from 'react';
import '../static/css/LoadingDot.css';

function LoadingDot() {
  return (
    <div className="loading-dot">
      <div className="dot"></div>
      <div className="dot"></div>
      <div className="dot"></div>
    </div>
  );
}

export default LoadingDot;
