import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import toastr from "toastr";
import { useAuth } from "../utils/provider/AuthProvider";

function Login() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const { accessToken, setAccessToken, setIsSuperuser } = useAuth();

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      let response = await fetch(`${process.env.REACT_APP_API_URL}/accounts/token/`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ username, password }),
      });

      if (response.ok) {
        const data = await response.json();
        setAccessToken(data.access);
        setIsSuperuser(true);
        navigate('/');
        toastr.success(`Giriş Başarılı. Hoşgeldin ${username}`);
      } else {
        response = await fetch(`${process.env.REACT_APP_API_URL}/accounts/token/refresh/`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${accessToken}`,
          },
        });

        if (response.ok) {
          const data = await response.json();
          setAccessToken(data.access);

          response = await fetch(`${process.env.REACT_APP_API_URL}/accounts/token/`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${data.access}`,
            },
            body: JSON.stringify({ username, password }),
          });

          if (response.ok) {
            const userData = await response.json();
            setAccessToken(userData.access);
            setIsSuperuser(true);
            navigate('/');
            toastr.success(`Giriş Başarılı. Hoşgeldin ${username}`);
          } else {
            setError('Giriş başarısız');
            toastr.error('Giriş başarısız');
          }
        } else {
          setError('Giriş yapılamadı');
          toastr.error('Giriş yapılamadı');
        }
      }
    } catch (error) {
      setError('Bir hata oluştu.');
      toastr.error(`Bir Hata Oluştu: ${error.message}`);
    }
  };

  return (
    <div className="container-fluid min-vh-100 d-flex align-items-center justify-content-center login-container">
      <div className="row w-100">
        <div className="col-md-12 col-lg-6 container">
          <div className="card shadow-sm">
            <div className="card-body">
              <h3 className="card-title mb-4">Giriş Yap</h3>
              <form onSubmit={handleSubmit}>
                <div className="mb-3">
                  <input
                    type="text"
                    className="form-control"
                    id="username"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    placeholder="Kullanıcı Adı"
                    required
                  />
                </div>

                <div className="mb-3">
                  <input
                    type="password"
                    className="form-control"
                    id="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    placeholder="Şifre"
                    required
                  />
                </div>

                {error && <div className="alert alert-danger">{error}</div>}

                <button
                  type="submit"
                  className="btn btn-primary"
                  style={{width: '100%'}}
                >
                  Giriş Yap
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;