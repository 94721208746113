import React from 'react';
import '../static/css/ErrorPage.css';

function NotDataPage() {
  return (
    <div className="error-page">
        <div className="error-content">
            <i className="error-icon">⚠️</i>
            <h1 className="error-message">Veri Bulunmamaktadır</h1>
        </div>
    </div>
  );
}

export default NotDataPage;
