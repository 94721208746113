import React from 'react';
import '../static/css/ErrorPage.css';

function ErrorPage() {
  return (
    <div className="error-page">
      <div className="error-content">
        <i className="error-icon">⚠️</i>
        <h1 className="error-message">Bir hata oluştu</h1>
      </div>
    </div>
  );
}

export default ErrorPage;
