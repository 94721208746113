import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import LoadingDot from './utils/LoadingDot';
import ErrorPage from './utils/ErrorPage';
import NotDataPage from './utils/NotDataPage';
import { useAuth } from './utils/provider/AuthProvider';
import { FaEdit, FaTrash } from 'react-icons/fa';
import axios from 'axios';
import toastr from 'toastr';

const CategoryCardList = () => {
  const [cards, setCards] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { accessToken, isSuperuser } = useAuth();
  const navigate = useNavigate();
  const getCategory = `${process.env.REACT_APP_API_URL}/site_defs/category/`;

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await fetch(getCategory, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${accessToken}`,
          }
        });
        const data = await response.json();
        setCards(data);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchCategories();
  }, [accessToken, getCategory]);

  if (loading) return <LoadingDot />;
  if (error) return <ErrorPage />;
  if (!cards.length) return <NotDataPage />;

  const handleDelete = async (id) => {
    try {
      await axios.delete(`${process.env.REACT_APP_API_URL}/site_defs/category/${id}/`, {
        headers: {
            'Authorization': `Bearer ${accessToken}`,
            'Content-Type': 'multipart/form-data'
          }
      });
      toastr.success('Kategori başarıyla silindi');
      setCards(cards.filter(card => card.id !== id));
    } catch (error) {
      toastr.error('Kategori silinirken bir hata oluştu.');
    }
  };

  const handleEdit = (card) => {
    navigate(`/create-update-category/${card.id}`, { state: { category: card } });
  };

  return (
    <div className="container d-flex justify-content-center" style={{ margin: '40px auto' }}>
      <div className="row">
        {cards.map(card => (
          <div className="col-12 col-md-4 mb-4" key={card.id}>
            <div className="card text-center">
              <div className="overflow">
                <Link to={`site_defs/product/${card.id}/`}>
                  <img src={card.image} alt={`Category Image ${card.id}`} className="card-img-top"/>
                </Link>
                {isSuperuser && (
                    <>
                      <button
                          className="btn btn-danger btn-sm position-absolute top-0 start-0 m-2"
                      onClick={() => handleDelete(card.id)}
                    >
                      <FaTrash size={15} />
                    </button>
                    <button
                      className="btn btn-primary btn-sm position-absolute top-0 end-0 m-2"
                      onClick={() => handleEdit(card)}
                    >
                      <FaEdit size={15} />
                    </button>
                  </>
                )}
              </div>
              <div className="card-body text-dark">
                <div className="d-flex justify-content-between align-items-center mb-2">
                  <h4 className="card-title">{card.name}</h4>
                </div>
                <Link to={`site_defs/product/${card.id}/`} className="btn btn-outline-dark">Daha Fazlası</Link>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CategoryCardList;
