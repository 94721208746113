import React, { useState, useEffect, createContext, useContext } from 'react';

const AuthContext = createContext();

export function AuthProvider({ children }) {
  const [accessToken, setAccessToken] = useState(localStorage.getItem('accessToken') || '');
  const [isSuperuser, setIsSuperuser] = useState(localStorage.getItem('isSuperuser') === 'true');

  useEffect(() => {
    localStorage.setItem('accessToken', accessToken);
    localStorage.setItem('isSuperuser', isSuperuser);
  }, [accessToken, isSuperuser]);

  return (
    <AuthContext.Provider value={{ accessToken, setAccessToken, isSuperuser, setIsSuperuser }}>
      {children}
    </AuthContext.Provider>
  );
}

export function useAuth() {
  return useContext(AuthContext);
}