import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import Navbar from './components/utils/Navbar';
import HomePage from './components/HomePage';
import CreateCategory from './components/boss/CreateCategory';
import CreateProduct from './components/boss/CreateProduct';
import Footer from './components/utils/Footer';
import ProductCardList from './components/ProductCardList';
import LoginPage from './components/boss/LoginPage';
import { AuthProvider } from './components/utils/provider/AuthProvider';
import ErrorPage from './components/utils/ErrorPage';
import CreateHomePageImage from './components/boss/CreateHomePageImage';

function ScrollToTop() {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return null;
}

function App() {
  return (
    <AuthProvider>
      <Router>
        <ScrollToTop />
        <Navbar />
        <Routes>
          <Route path="/" element={<HomePage />}/>
          <Route path="/create-update-homepage-image" element={<CreateHomePageImage/>}/>
          <Route path="/create-update-category" element={<CreateCategory/>}/>
          <Route path="/create-update-category/:id" element={<CreateCategory/>}/>
          <Route path="/create-update-product" element={<CreateProduct/>}/>
          <Route path="/create-update-product/:productId" element={<CreateProduct />} />
          <Route path="/boss" element={<LoginPage />}/>
          <Route path="/logout" element={<HomePage />}/>
          <Route path="/site_defs/product/:categoryId" element={<ProductCardList />}/>

          <Route path="*" element={<ErrorPage />}/>
        </Routes>
        <Footer />
      </Router>
    </AuthProvider>
  );
}

export default App;
