import React, { useEffect, useState, useRef } from 'react';
import '../static/css/Carousel.css';

function Carousel() {
  const [images, setImages] = useState([]);
  const carouselRef = useRef(null);
  const homePageImageUrl = `${process.env.REACT_APP_API_URL}/site_defs/homepage-image/`;

  useEffect(() => {
    const fetchImages = async () => {
      try {
        const response = await fetch(homePageImageUrl);
        const datas = await response.json();
        const imagesArray = [];
        datas.map(data => (
          imagesArray.push(data['image'])
        ));
        setImages(imagesArray);
      } catch (error) {
        console.error('Error fetching images:', error);
      }
    };

    fetchImages();

    const interval = setInterval(() => {
      if (carouselRef.current) {
        const carousel = new window.bootstrap.Carousel(carouselRef.current);
        carousel.next();
      }
    }, 3000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div id="carouselExampleCaptions" className="carousel slide mt-2" ref={carouselRef}>
      <div className="carousel-inner">
        {images.map((image, index) => (
          <div
            key={index}
            className={`carousel-item ${index === 0 ? 'active' : ''}`}
            style={{ backgroundImage: `url(${image})` }}
          >
          </div>
        ))}
      </div>
      <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="prev">
        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
      </button>

      <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="next">
        <span className="carousel-control-next-icon" aria-hidden="true"></span>
      </button>
    </div>
  );
}

export default Carousel;
